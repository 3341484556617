import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {environment} from 'src/environments/environment';
import {AppConfig} from '../config';
import {
  Addon,
  CheckoutRes,
  IAddonProductsGroup,
  OfflinePayment,
  PaymentInitiateRes,
  PaymentLink,
  PaymentMode,
  PluginManagerRes,
  PollingRes,
  Product,
  ProductData,
  QrRes,
  ReceiptEmail,
} from '../models/product.model';
import {catchError, delay, retry, take} from 'rxjs/operators';
import {Filter} from '../models/filter.model';
import {RzpImg} from '../models/asset.modal';
import {IRatingFeedback} from '../models/kioskUiSettings.model';
import {IIndividualTaxDetails, IReceiptTemplate,} from '../models/config.model';

function _window(): any {
  return window;
}

type IGetInTouch = Record<string, unknown>;

@Injectable({
  providedIn: "root",
})
export class DataService {
  token: string;
  baseUrl = environment.BASE_URL;

  constructor(private http: HttpClient, private router: Router) {
    if (
      sessionStorage.getItem(`${sessionStorage.getItem("accountId")}_token`) ===
      null
    ) {
      this.router.navigate([""]).then((r) => r);
    } else {
      this.token = sessionStorage.getItem(
        `${sessionStorage.getItem("accountId")}_token`
      );
    }
  }

  // TODO 1: Remove any return type
  getLocations(): Observable<any> {
    const location = sessionStorage.getItem("location");
    if (!location) {
      return this.http.get(
        `${this.baseUrl}${AppConfig.endPoints.locationsURL}`
      );
    }

    return of(new Error("Location not set"));
  }

  addItemInstruction(
    orderID: string,
    itemID: string,
    instruction: string
  ): Observable<any> {
    const URL = `${this.baseUrl}${AppConfig.endPoints.addInst}${orderID}/itemId/${itemID}`;
    const location = sessionStorage.getItem("location");
    const body = {
      storeId: location,
      instruction,
    };

    return this.http.post(URL, body);
  }

  deleteItemInstruction(orderID: string, ItemID: string): Observable<any> {
    const path = AppConfig.endPoints.deleteInst;
    const location = sessionStorage.getItem("location");

    return this.http.delete(
      `${this.baseUrl}${path}${orderID}/itemId/${ItemID}/storeId/${location}`
    );
  }

  setPaymentModeOffline(orderId: string): Observable<OfflinePayment> {
    const URL = `${this.baseUrl}${AppConfig.endPoints.offlinePaymentURL}`;

    return this.http.put<OfflinePayment>(`${URL}${orderId}/offline`, {});
  }

  getOrderStatusQr(orderId: string): Observable<{
    data: string;
  }> {
    return this.http.get<{
      data: string;
    }>(`${this.baseUrl}${AppConfig.endPoints.statusQR}${orderId}`);
  }

  getFiltersList(accountId, token?): Observable<Filter[]> {
    const KterminalId = sessionStorage.getItem("kTerminalId");
    const pathString = AppConfig.endPoints.filtersURL;
    const locationId = sessionStorage.getItem("location");
    const pfi =
      "_id,regionalLanguage,groups,itemName,price,isVeg,desc,metaInfo.itemName,metaInfo.categoryIds,metaInfo.price,desc,isVeg,metaInfo.addOns,assetsLinked,metaInfo.isInventoryEnabled,metaInfo.quantity";
    const gfi = "_id,label,regionalLanguage";
    const PATH = `${pathString}${locationId}?terminalId=${KterminalId}&productFI=${pfi}&isPopulate=true&groupFI=${gfi}`;
    const URL = `${this.baseUrl}${PATH}`;
    const headers = new HttpHeaders()
      .set(
        "Authorization",
        token ? token : sessionStorage.getItem(`${accountId}_token`)
      )
      .set("Content-Type", "application/json")
      .set("Accept", "*");
    return this.http.get<Filter[]>(URL, { headers });
  }

  initiatePayment(orderId: string): Observable<PaymentInitiateRes> {
    const URL = `${this.baseUrl}${AppConfig.endPoints.paymentURL}${orderId}/?fI=paymentGateway.id`;

    return this.http.get<PaymentInitiateRes>(URL);
  }

  getProducts(typeId: string, storeId: string): Observable<Product[]> {
    const limit = 300;

    const skip = 0;
    let URL;

    const fi =
      "metaInfo,product.regionalLanguage,product._id,product.item,product.description,product.veg/nonVeg,groups._id";
    const sb = "metaInfo.price,1,includeImage=true";
    if (typeId === "all") {
      URL = `${this.baseUrl}api/sok/stores/productsLinked/${storeId}?limit=${limit}&skip=${skip}&sortByFields=${sb}&enableDisableF=true&fI=${fi}`;
    } else if (typeId !== "all") {
      URL = `${this.baseUrl}api/sok/stores/productsLinked/${storeId}?limit=${limit}&skip=${skip}&sortByFields=${sb}&groupIds=${typeId}&enableDisableF=true&fI=${fi}`;
    }

    return this.http.get<Product[]>(URL).pipe(
      retry(2), // Retry API call 2 times on error
      delay(3000), // Delay for 3 seconds between retries
      take(3), // Stop retrying after 3 attempts
      catchError((error) => {
        // Handle the error
        console.log("API error:", error);
        return throwError(error);
      })
    );
  }

  buildCatList(storeId: string, accountId, token?): Observable<any> {
    const KterminalId = sessionStorage.getItem("kTerminalId");
    const URL = this.baseUrl + AppConfig.endPoints.catProdURL + storeId;
    const pfi =
      "_id,regionalLanguage,groups,metaInfo.itemName,metaInfo.price,metaInfo.outOfStockStatus,metaInfo.categoryIds,metaInfo.description,veg/nonVeg,metaInfo.addOns,parcelCharge,assetsLinked,metaInfo.isInventoryEnabled,metaInfo.quantity";
    const cfi = "name,_id,sequence,regionalLanguage,parcelCharge";
    const headers = new HttpHeaders()
      .set(
        "Authorization",
        token ? token : sessionStorage.getItem(`${accountId}_token`)
      )
      .set("Content-Type", "application/json")
      .set("Accept", "*");

    const url = `${URL}/?isPopulate=true&terminalId=${KterminalId}&categoryFI=${cfi}&productFI=${pfi}&ngsw-bypass=true`;
    return this.http.get(url, { headers });
  }

  // to get cat products by catId
  getCatProdsByCatId(catId: string, storeId: string): Observable<any> {
    const BASE_URL = this.baseUrl;

    const limit = 100;
    const skip = 0;
    const path = "api/sok/stores/productsLinked/";
    const sb = "metaInfo.price,1,includeImage=true";
    const fi =
      "metaInfo,product.regionalLanguage,product._id,product.item,product.description,product.veg/nonVeg,groups._id";
    const URL = `${BASE_URL}${path}${storeId}?limit=${limit}&skip=${skip}&sortByFields=${sb}&categoryIds=${catId}&enableDisableF=true&fI=${fi}`;

    return this.http.get(URL);
  }

  getPopularProducts(locationId: string): Observable<ProductData> {
    const KterminalId = sessionStorage.getItem("kTerminalId");
    const BASE_URL = this.baseUrl;
    const fi =
      "_id,regionalLanguage,metaInfo.itemName,metaInfo.price,metaInfo.outOfStockStatus,metaInfo.description,veg/nonVeg,metaInfo.addOns,metaInfo.isInventoryEnabled,metaInfo.quantity,metaInfo.categoryIds";
    const path = AppConfig.endPoints.popularURL;
    const URL = `${BASE_URL}${path}${locationId}?terminalId=${KterminalId}&productFI=${fi}`;

    return this.http.get<ProductData>(URL);
  }

  // to get tax details at checkout
  createCheckOut(
    cartItems: Product[],
    dineType: string,
    taxData,
    orderDetails
  ): Observable<CheckoutRes> {
    const storeId = sessionStorage.getItem("location");
    const kTerminal = sessionStorage.getItem("kTerminalId");
    const superkTerminalId = sessionStorage.getItem("superkTerminalId");
    const rawPayload = {
      products: cartItems,
      storeId,
      kTerminalId: kTerminal ? kTerminal : undefined,
      superkTerminalId: superkTerminalId ? superkTerminalId : undefined,
      dineType,
      orderSource: "kiosk",
    };

    let disableTaxCalculation = false;

    if (taxData) {
      disableTaxCalculation = true;
      const payment = {
        price: taxData.price,
        items: taxData.items,
        amountToBePaid: taxData.price.total,
        amountPaid: 0,
        isPaid: false,
        status: "notPaid",
      };
      rawPayload["payment"] = payment;
      rawPayload["orderDetails"] = orderDetails;
    }

    const url = `${this.baseUrl}${AppConfig.endPoints.checkOutURL}?disableTaxCalculation=${disableTaxCalculation}`;

    return this.http.post<CheckoutRes>(url, rawPayload);
  }

  // to get tax details at checkout
  updateOrder(
    cartItems: any,
    orderId: string,
    dineType: string,
    taxData,
    orderDetails
  ): Observable<any> {
    const storeId = sessionStorage.getItem("location");

    const rawPayload = {
      products: cartItems,
      dineType,
      storeId,
      orderSource: "kiosk",
    };

    let disableTaxCalculation = false;
    if (taxData) {
      disableTaxCalculation = true;
      const payment = {
        price: taxData.price,
        items: taxData.items,
        amountToBePaid: taxData.price.total,
        amountPaid: 0,
        isPaid: false,
        status: "notPaid",
      };
      rawPayload["payment"] = payment;
      rawPayload["orderDetails"] = orderDetails;
    }

    const URL = `${this.baseUrl}${AppConfig.endPoints.checkOutURL}${orderId}?disableTaxCalculation=${disableTaxCalculation}`;

    return this.http.put(URL, rawPayload);
  }

  getQR(orderId: string): Observable<QrRes> {
    const accountId = sessionStorage.getItem("token");
    const headers = new HttpHeaders()
      .set("Authorization", sessionStorage.getItem(`${accountId}_token`))
      .set("Content-Type", "application/json")
      .set("Accept", "*");
    return this.http.get<QrRes>(
      `${this.baseUrl}${AppConfig.endPoints.qrURL}${orderId}?ngsw-bypass=true`,
      { headers }
    );
  }

  get nativeWindow(): any {
    return _window();
  }

  getRazorpayConfig(rzpImg: string) {
    const rzpThemeColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--primary");
    const paymentKey = sessionStorage.getItem("paymentkey");
    const name = sessionStorage.getItem("accountName");
    return {
      key: paymentKey,
      name,
      description: "True couverture chocolates",
      image: rzpImg,
      amount: "1",
      currency: "INR",
      order_id: "order_JZMufGF8DhHO9Y",
      handler: new Function(),
      modal: {
        backdropclose: true,
        ondismiss: new Function(),
        animation: true,
      },
      notes: {
        orderId: "",
        payment_id: "",
        accountId: "",
        customer_id: "",
        linkedAccount: "",
        orderDocId: "",
        env: "",
      },
      theme: {
        color: `${rzpThemeColor}`,
      },
    };
  }

  paymentPolling(id: string): Observable<PollingRes> {
    const BASE_URL = this.baseUrl;
    const accountId = sessionStorage.getItem("accountId");
    const headers = new HttpHeaders()
      .set("Authorization", sessionStorage.getItem(`${accountId}_token`))
      .set("Content-Type", "application/json")
      .set("Accept", "*");
    return this.http.get<PollingRes>(
      `${BASE_URL}api/sok/orders/checkOut/payment/success/${id}`,
      { headers }
    );
  }

  sendPaymentReceipt(orderId: string, phoneNo: any): Observable<ReceiptEmail> {
    const URL = `${this.baseUrl}${AppConfig.endPoints.sendPaymentReceipt}`;
    return this.http.put<ReceiptEmail>(
      `${URL}?orderId=${orderId}&sendAddress=${phoneNo}`,
      {}
    );
  }

  getRazorpayAccountKey(): Observable<PluginManagerRes> {
    return this.http.get<PluginManagerRes>(
      `${this.baseUrl}api/pluginManager/payment/type/razorpay`
    );
  }

  quantityCheck(cartItems: any): Observable<any> {
    const URL = `${this.baseUrl}api/sok/stores/quantityCheck`;
    const storeId = sessionStorage.getItem("location");
    return this.http.post(URL, {
      products: cartItems,
      storeId,
    });
  }

  getAccountName(token: string | string[]) {
    const headers = new HttpHeaders()
      .set("Authorization", token)
      .set("Content-Type", "application/json")
      .set("Accept", "*");
    return this.http.get(
      `${this.baseUrl}api/account/self/data?fI=name,images.logo.url`,
      { headers }
    );
  }

  getPaymentMode(): Observable<PaymentMode> {
    return this.http.get<PaymentMode>(
      `${this.baseUrl}api/sok/settings/moduleType/payment`
    );
  }

  discardOrder(orderId: string, screen: string) {
    const superkTerminalId = sessionStorage.getItem("superkTerminalId");
    let payload: unknown = {
      orderIds: [orderId],
      screen,
      kioskId: "kiosk1",
    };

    const kTerminalId = sessionStorage.getItem("kTerminalId");
    if (superkTerminalId) {
      payload = {
        orderIds: [orderId],
        screen,
        kioskId: kTerminalId,
        superkTerminalId,
      };
    }

    return this.http.put(
      `${this.baseUrl}api/sok/orders/updatePickUpStatusKiosk/discarded`,
      payload
    );
  }

  checkImageForRazorpay(): Observable<RzpImg> {
    return this.http.get<RzpImg>(`${this.baseUrl}api/account/self/logo`);
  }

  getStoreImage(assetId: string) {
    if (assetId) {
      return `${this.baseUrl}${AppConfig.endPoints.storeImageUrl}${assetId}`;
    } else {
      return null;
    }
  }

  sendGetInTouchData(payload: {
    subject: string;
    name: string;
    email: string;
    phone: string;
    message: string;
  }): Observable<IGetInTouch> {
    return this.http.post<IGetInTouch>(
      `${this.baseUrl}api/customer/contactUs`,
      payload
    );
  }

  sendPaymentLink(address: any, orderID: string): Observable<PaymentLink> {
    return this.http.post<PaymentLink>(
      `${this.baseUrl}${AppConfig.endPoints.paymentLink}${orderID}`,
      { sendAddress: address }
    );
  }

  getAddons(): Observable<Addon> {
    return this.http.get<Addon>(`${this.baseUrl}${AppConfig.endPoints.addons}`);
  }

  getProductsAddons(locationId:string): Observable<IAddonProductsGroup[]> {
    return this.http.get<IAddonProductsGroup[]>( `${this.baseUrl}api/sok/stores/addOnGroups/locationId/${locationId}`);
  }

  submitRatingFeedback(obj: IRatingFeedback) {
    return this.http.post<IGetInTouch>(
      `${this.baseUrl}api/sok/orders/customerFeedback`,
      obj
    );
  }

  downloadSalesReport(
    startTime: number,
    endTime: number,
    terminalId: string,
    storeId: string
  ) {
    const url = `${this.baseUrl}api/sok/analytics/productSales?startDateMilliSec=${startTime}&endDateMilliSec=${endTime}&reportType=print&orderSource=kiosk&terminalId=${terminalId}&tId=${terminalId}&pickUpStoreIds=${storeId}`;
    return this.http.get(url);
  }

  loadAllTaxDetails(): Observable<IIndividualTaxDetails[]> {
    return this.http.get<IIndividualTaxDetails[]>(
      `${this.baseUrl}api/sok/stores/tax`
    );
  }

  loadReceiptTemplates(terminalId: string): Observable<IReceiptTemplate> {
    return this.http.get<IReceiptTemplate>(
      `${this.baseUrl}api/sok/stores/templates?terminalId=${terminalId}`
    );
  }
}
