import { AppState } from '../app.state';


export const productsSelector = ({ filter, store }) => (state: AppState) => {
  if (filter) {
    const key = store + filter;
    return state.Products?.products?.[key];
  } else {
    const key = store + filter;
    return state.Products.products?.[key];
  }
};
export const filtersSelector = (state: AppState) => state.Products.filters;
export const productFailSelector = (state: AppState) => state.Products.productLoadFail;

export const productKeySelector = (state: AppState) => state.Products.productKey;

export const cartProductsSelector = (state: AppState) => state.Products.cartProducts;

export const comboSelector = (state: AppState) => state.Products.cartProducts.filter((v) => v?.id === state.Products.selectedProduct.id);


export const selectedProductSelector = (state: AppState) => state.Products.selectedProduct;

export const selectedProductCopy = (state: AppState) => state?.Products?.selectedProductC;

export const totalPriceSelector = (state: AppState) => state.Products.totalPrice;

export const storeNameSelector = (state: AppState) => state.Products.storeName;

export const categoriesSelector = ({ store }) => (state: AppState) => state.Products.categories[store];

export const categoryInfoByProductIdSelector = ({ store,pId }) => (state: AppState) => {
  const c = state.Products.categories[store]?.find(c => c.products?.find(x => x.id === pId))
  return c?.categoryInfo;
};


export const getCatNameFromId = (cId: string | string[]) => (state: AppState) => {
  const store = sessionStorage.getItem('location')
    if (Array.isArray(cId)) {
      return state.Products.categories[store].map(c => {
        if (cId.includes(c.categoryInfo?._id)) {
          return c.categoryInfo?.name
        }
      }).filter(x => !!x);
    } else {
      return state.Products.categories[store].find(c => c.categoryInfo._id === cId).categoryInfo?.name
    }

  }

