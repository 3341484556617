export const AppConfig = {
  wallpaperLogo: 'assets/images/smoor-wallpaper-logo.png',
  printerURL: 'http://127.0.0.1:4111/print',
  printStatusUrl: 'http://127.0.0.1:4111/printerStatus',
  printerPollingDuration: 4000,
  logo: 'assets/images/smoor.svg',
  slotCheckInterval: 60000,
  paymentPollingIntervalIfSocketConnected: 20, // inseconds
  paymentPollingIntervalIfSocketNotConnected: 5,
  modalTime: 20,
  timeOut: 20,
  paymentSessionTimer: 181,
  slotUpdateMessage: 'Slot Settings Updated',
  inventoryChange: 'Inventory Settings Updated',
  version: '1.0.8',
  endPoints: {
    locationsURL: 'api/asset?isLinkedReq=false&type=location',
    addons:
      'api/asset?isLinkedReq=false&type=properties&disableRoleAuth=true&fI=_id,label,displayName,type,products,accountId',
    offlinePaymentURL: 'api/payments/mode/',
    filtersURL: 'api/sok/stores/groupInfo/products/locationId/',
    paymentURL: 'api/payments/initiate/',
    categoryURL: 'api/sok/stores/categoryInfo/locationId/',
    catProdURL: 'api/sok/stores/categoryInfo/products/locationId/',
    checkOutURL: 'api/sok/orders/checkOut/',
    qrURL: 'api/sok/orders/checkOut/qrCode/',
    sendPaymentReceipt: 'api/payments/sendReceipt/',
    getImageById: 'api/payments/sendReceipt/',
    invoiceContent: 'api/payments/invoiceContent/orderId/',
    tokenContent: 'api/payments/tokenContent/orderId/',
    printPreview: 'api/payments/getRecieptContent/orderId/',
    storeImageUrl: 'api/smartAsset/assetData/image/',
    paymentLink: 'api/payments/sendPaymentLink/orderId/',
    statusQR: 'api/sok/orders/status/qrCode/orderId/',
    addInst: 'api/sok/orders/add/instructions/orderId/',
    deleteInst: 'api/sok/orders/instructions/orderId/',
    popularURL: 'api/sok/stores/popularProducts/locationId/',
  },
  // printer res
  noPaper: 'paperempty',
  printerNotClosed: 'coveropen',
  printerNotConnected: 'disconnect',
  printerOffline: 'offline',
  mlProductFilterName: 'popular',
};

export const KeyboardLayout = [
  {
    default: [
      '1 2 3 4 5 6 7 8 9 0 = {bksp}',
      'q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \'',
      'z x c v b n m , . /',
      '@ @gmail.com {space}',
    ],
    shift: [
      '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      '{lock} A S D F G H J K L : "',
      '{shift} Z X C V B N M &lt; &gt; ? {shift}',
      '.com @ {space}',
    ],
  },
  {
    default: ['1 2 3', '4 5 6', '7 8 9', '0 {bksp}'],
  },
];

export const KeyboardWordsLayout = {
  default: [
    'q w e r t y u i o p {bksp}',
    'a s d f g h j k l',
    'z x c v b n m {space}',
  ],
};

export const KeyboardThemes = {
  numeric: 'hg-theme-default hg-layout-numeric numeric-theme',
};
